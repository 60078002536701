.page-title {
    display: block !important;
    text-align: center;
    font-size: 2rem;
    margin-top: 5px;
}

.form-container {
    width: 50%;
    margin: auto;
}